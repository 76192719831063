import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';

@Injectable({
    providedIn: 'root',
})


export class SubHeaderLinkResolver extends AppComponentBase implements Resolve<string> {
    isAuthority: boolean;

    constructor(injector: Injector) {
        super(injector);

        this.isAuthority = !this.permission.isGranted('Pages.Applicant');
    }

    resolve(route: ActivatedRouteSnapshot): string {
        const authorityLink = route.data['authorityLink'];
        const applicantLink = route.data['applicantLink'];

        if (this.isAuthority) {
            return authorityLink;
        } else {
            return applicantLink;
        }
    }
}
