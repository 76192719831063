import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SubHeaderComponent } from './sub-header.component';
import { SubHeaderLinkResolver } from './subheader-link-resolver';

@NgModule({
    imports: [CommonModule],
    providers: [SubHeaderLinkResolver],
    declarations: [SubHeaderComponent],
    exports: [SubHeaderComponent],
})
export class SubheaderModule {}
