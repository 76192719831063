import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable()
export class CustomValidationService {

    public emailValidator(control: FormControl) {
        if (control.value) {
            const error = { 'invalidEmail': true };

            if (control.value.length === 0) {
                return error;
            }

            const regexp = new RegExp('^(?:[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-'
            + '\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:'
            + '(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b'
            + '\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$');

            const match = regexp.test(control.value);

            if (!match) {
                return error;
            }
        }

        return null;
    }

    public phoneValidator(control: FormControl) {
        if (control.value) {
            const error = { 'invalidPhone': true };

            if (control.value.length === 0) {
                return error;
            }

            const regexp = new RegExp(`^(\\b(0041|0)|\\B\\+41)(\\s?\\(0\\))?(\\s)?[1-9]{2}(\\s)?[0-9]{3}(\\s)?[0-9]{2}(\\s)?[0-9]{2}\\b$`);

            const match = regexp.test(control.value);

            if (!match) {
                return error;
            }
        }

        return null;
    }

    public phoneValidatorInternational(control: FormControl) {
        if (control.value) {
            const error = { 'invalidPhone': true };

            if (control.value.length === 0) {
                return error;
            }

            const regexp = new RegExp(`(^\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$)|(^(\\b(0041|0)|\\B\\+41)(\\s?\\(0\\))?(\\s)?[1-9]{2}(\\s)?[0-9]{3}(\\s)?[0-9]{2}(\\s)?[0-9]{2}\\b$)`);

            const match = regexp.test(control.value);

            if (!match) {
                return error;
            }
        }

        return null;
    }

    public ahvValidator(control: FormControl) {
        if (control.value) {
            const error = { 'invalidAhv': true };

            if (control.value.length === 0) {
                return error;
            }

            // regex to check the format
            const regexp = new RegExp(`^756\\.?[0-9]{4}\\.?[0-9]{4}\\.?[0-9]{2}$`);
            if (!regexp.test(control.value)) {
                return error;
            }

            // remove dots for calculating the checksum
            const ahvNumber = control.value.replace(/\./g, '');

            // extraxt the first 12 digits (without the check digit)
            const digits = ahvNumber.substring(0, 12).split('').map(Number);
            const checkDigit = Number(ahvNumber.charAt(12)); // last digit is the check sum

            // calculate checksum
            let sum = 0;
            for (let i = 0; i < digits.length; i++) {
                sum += digits[i] * (i % 2 === 0 ? 1 : 3);
            }

            const calculatedCheckDigit = (10 - (sum % 10)) % 10; // Round up to the next multiple of 10.

            if (calculatedCheckDigit !== checkDigit) {
                return error;
            }
        }

        return null;
    }

    public glnValidator(control: FormControl) {
        if (control.value) {
            const error = { 'invalidGln': true };

            let gln: number = +control.value;
            if (control.value.length !== 13 || isNaN(gln)) {
                return error;
            }

            const check = gln % 10;

            let sum = 0;
            for (let i = 0; i < 6; i++) {
                gln = Math.floor(gln / 10);
                sum = sum + (gln % 10) * 3;
                gln = Math.floor(gln / 10);
                sum = sum + (gln % 10) * 1;
            }

            const result = (300 - sum) % 10;

            if (result !== check) {
                return error;
            }
        }

        return null;
    }

    public uidValidator(control: FormControl) {
        let uid: string = control.value;
        if (uid) {
            const error = { 'invalidUid': true };

            const regexp = new RegExp(/^(CHE)[-]?\d{3}[.]?\d{3}[.]?\d{3}$/);
            const match = regexp.test(uid);
            if (!match) {
                return error;
            }

            uid = uid.replace('-', '');
            uid = uid.replace('.', '');
            uid = uid.replace('.', ''); // Replace removes only one dot

            // Calculate the checksum
            let multiplicatedDigits: number[] = [];

            multiplicatedDigits.push(Number(uid.charAt(3)) * 5);
            multiplicatedDigits.push(Number(uid.charAt(4)) * 4);
            multiplicatedDigits.push(Number(uid.charAt(5)) * 3);

            multiplicatedDigits.push(Number(uid.charAt(6))  * 2);
            multiplicatedDigits.push(Number(uid.charAt(7))  * 7);
            multiplicatedDigits.push(Number(uid.charAt(8)) * 6);

            multiplicatedDigits.push(Number(uid.charAt(9)) * 5);
            multiplicatedDigits.push(Number(uid.charAt(10)) * 4);
            let checkDigit = Number(uid.charAt(11));

            let sum = multiplicatedDigits.reduce((partialSum, a) => partialSum + a, 0);
            let moduloRest = sum % 11;

            if (moduloRest == 0) {
                if (checkDigit != 0) {
                    return error;
                }
            } else {
                if ((11 - moduloRest) != checkDigit) {
                    return error;
                }
            }
        }

        return null;
    }

    public getPostCodeValidators(notRequired: boolean = false) {
        const validators = [Validators.minLength(4),
            Validators.maxLength(4),
            Validators.pattern('^[0-9]*$')];

        if (!notRequired) {
            validators.push(Validators.required);
        }

        return validators;
    }

    public numbersOnlyValidation(event) {
        const inp = String.fromCharCode(event.keyCode);
        /*Allow only numbers*/
        if (!/^\d+$/.test(inp)) {
            event.preventDefault();
        }
    }

}

