import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceCategoryFilteringService } from './service-category-filtering.service';
import { UserService } from './user.service';
import { ServiceCategory } from '@app/services/service-category.enum';
import { EnService } from './en.service';
import { MySironaLegitimationService } from './my-sirona-legitimation.service';

@Injectable()
export class EmploymentNotificationGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private legitimationService: MySironaLegitimationService,
        private router: Router,
        private enService: EnService,
        private serviceCategoryFilteringService: ServiceCategoryFilteringService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


        this.serviceCategoryFilteringService.setServiceCategoryFromRoute('personlicence');

        if (abp.auth.isGranted('Pages.Authority')) {
            let tree = this.router.parseUrl(AppConsts.authorityStartUrl);
            tree.queryParams = route?.queryParams;
            return tree;
        }


        return this.userService.getUser().pipe(
            map(user => {
                const isIdentified = user.userIdentificationStatus.identificationStatus === AppConsts.codes.identificationStatus.granted;
                if (isIdentified) {
                    this.enService.createEn(this.legitimationService.GetPersonId()).subscribe((caseId: string) => {
                        this.router.navigate([`app/en/${caseId}/wizard/contact`]);
                    });
                } else {
                    let tree = this.router.parseUrl('app/my-sirona/user-information');
                    tree.queryParams = route?.queryParams;
                    return tree;
                }
            })
        );
    }
}
