import { Injectable, Injector } from '@angular/core';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from '@app/shared/services/profession-configuration.service';
import { AppConsts } from '@shared/AppConsts';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DateTime } from 'luxon';
import { ApplicationServiceProxy, ApplicationUploadFilesInput, CreateLegOutput, GeneralDocumentGroupDto, GeneralFileDto, GeneralFileInfoDto, GetLegDocumentsInput, GetLegFileInput, GetLegOutput, LegAuthorizationDto, LegAuthorizationServiceProxy, LegDocumentServiceProxy, LegDocumentsOutput, LegDto, LegIdentificationDto, LegIdentificationServiceProxy, LegServiceProxy, LegStatus, LegUidDataDto, LegUidSearchServiceProxy, ReleaseLegOutput } from '@shared/service-proxies/service-proxies';

@Injectable()
export class LegService extends ApplicationService {

    leg: LegDto;
    legResultDocuments: GeneralFileInfoDto[];

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private legService: LegServiceProxy,
        private uidSearchService: LegUidSearchServiceProxy,
        private identificationService: LegIdentificationServiceProxy,
        private authorityService: LegAuthorizationServiceProxy,
        private documentService: LegDocumentServiceProxy,
    ) {
        super(injector, codeService, appService);
    }

    getApplications(filter: string, sorting: string, maxResultCount: number, skipCount: number, includeSelfadministrated: boolean) {
        return this.legService.getLegs(
            includeSelfadministrated,
            filter,
            sorting,
            maxResultCount,
            skipCount
        );
    }


    createLeg(): Observable<CreateLegOutput> {
        return this.legService.createLeg().pipe(
            tap((output: CreateLegOutput) => {
                this.leg = null;
            })
        );
    }

    deleteLeg(): Observable<void> {
        return this.appService.deleteApplication(this.leg.application.caseId);
    }

    acceptLeg(): Observable<void> {
        return this.legService.acceptApplication(this.leg.application.caseId);
    }

    getLeg(caseId: string): Observable<GetLegOutput> {
        this.leg = null;
        return this.getApplication(caseId).pipe(
            switchMap(() =>
                this.legService.getLeg(caseId).pipe(
                    tap((output: GetLegOutput) => {
                        this.leg = output.leg;
                    })
                )
            )
        );
    }

    getMyLegs(): Observable<LegDto[]> {
        return this.legService.getMyLegs();
    }


    isInLegStatus(expectedStatus: LegStatus): boolean {
        return this.leg.currentStep === expectedStatus;
    }

    getEvaluationComments(): Observable<string> {
        return this.legService.getEvaluationComments(this._caseId);
    }

    getLastSupplementalClaim(): Observable<string> {
        return this.legService.getLastSupplementalClaim(this._caseId);
    }

    releaseLeg(): Observable<ReleaseLegOutput> {
        return this.legService.releaseLeg(this.leg.application.caseId);
    }

    getNextStatus(status: LegStatus): Observable<LegStatus> {
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        return this.legService.getNextStatus(this._caseId, status).pipe(
            map((status) => {
                // Authority goes to 'finish review' and not to 'release'
                if (isAuthority && status === LegStatus.Release) {
                    return LegStatus.FinishReview;
                }
                return status;
            })
        );
    }


    getFullApplicationBaseUrl(): string {
        let url;
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        url = isAuthority ? AppConsts.authorityBaseUrl + '/applications/' : AppConsts.applicantBaseUrl;
        return url;
    }

    protected getNextStep(path: string): string {
        const url = this.getFullCaseUrl() + 'wizard/' + path;
        return url;
    }

    getFullCaseUrl(): string {
        const url = this.getFullApplicationBaseUrl() + `/${this.getNavigationContext()}/${this.caseId}/`;
        return url;
    }

    getStepUrl(nextStep: LegStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }

    get firstStep(): LegStatus {
        let firstStep = this.leg.firstStep;
        return firstStep;
    }

    getFiles(step: LegStatus): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetLegDocumentsInput({ caseId: this._caseId, step: step });
        return this.documentService.getDocuments(input).pipe(map((output: LegDocumentsOutput) => output.documents));
    }

    getFile(step: LegStatus, id: number): Observable<GeneralFileDto> {
        const input = new GetLegFileInput();
        input.caseId = this._caseId;
        input.step = step;
        input.id = id;
        return this.documentService.getFile(input);
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadFiles(input);
    }

    deleteFile(step: LegStatus, id: number): Observable<void> {
        return this.documentService.deleteFile(this._caseId, step, id);
    }

    get caseId(): string {
        return this.leg.application.caseId;
    }

    // UidSerach Step
    setLegUidSearchStepToReview() {
        this.setStepToReview(LegStatus.UidSearch);
    }

    getUidData(): Observable<LegUidDataDto> {
        return this.uidSearchService.get(this.caseId);
    }

    createOrUpdateUidData(uidData: LegUidDataDto): Observable<any> {
        this.setStepToReview(LegStatus.UidSearch);
        return this.uidSearchService.createOrUpdate(this._caseId, uidData).pipe(
            tap((newStatusId: LegStatus) => {
                this.leg.uidData = uidData;
                this.leg.currentStep = newStatusId;
            })
        );
    }

    // Authorization Step
    setLegAuthorizationStepToReview() {
        this.setStepToReview(LegStatus.Authorization);
    }

    getAuthorization(): Observable<LegAuthorizationDto> {
        return this.authorityService.get(this.caseId);
    }

    createOrUpdateAuthorization(authorization: LegAuthorizationDto): Observable<any> {
        this.setStepToReview(LegStatus.Authorization);
        return this.authorityService.createOrUpdate(this._caseId, authorization).pipe(
            tap((newStatusId: LegStatus) => {
                this.leg.authorization = authorization;
                this.leg.currentStep = newStatusId;
            })
        );
    }

    // Identification Step
    setLegIdentificationStepToReview() {
        this.setStepToReview(LegStatus.Identification);
    }

    getIdentification(): Observable<LegIdentificationDto> {
        return this.identificationService.get(this.caseId);
    }

    createOrUpdateIdentification(authorization: LegIdentificationDto): Observable<any> {
        this.setStepToReview(LegStatus.Identification);
        return this.identificationService.createOrUpdate(this._caseId, authorization).pipe(
            tap((newStatusId: LegStatus) => {
                this.leg.currentStep = newStatusId;
            })
        );
    }

    checkDocuments(): Observable<LegStatus[]> {
        return this.legService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case LegStatus.UidSearch:
                return 'uid-search';
            case LegStatus.Authorization:
                return 'authorization';
            case LegStatus.Identification:
                return 'identification';


            case LegStatus.Release:
                return 'release';
            case LegStatus.FinishReview:
                return 'finish-review';

            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }
}
