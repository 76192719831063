import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetEGovSponsorshipOwnerLegitimationDto, LegitimationDto, LegitimationSearchInput, LegitimationServiceProxy, LegitimationStatus, SearchLegitimationDto, SetLegitimationStatusDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';


@Injectable({
    providedIn: 'root',
})
export class LegitimationService extends AppComponentBase {

    constructor(
        injector: Injector,
        private legitimationService: LegitimationServiceProxy,
    ) {
        super(injector);
    }

    public GetLegitimationsForEGovDossier(id: number, filter: string, sorting: string, skipCount: number, maxResultCount: number) {
        let input = new LegitimationSearchInput({
            eGovSponsorshipId: id,
            filter: filter,
            sorting: sorting,
            maxResultCount: maxResultCount,
            skipCount: skipCount
        });
        return this.legitimationService.searchLegitimationsForEGovDossier(input);
    }

    IsLegitimationOwnerExisting(uid: string): Observable<boolean>{
        return this.legitimationService.isLegitimationOwnerExisting(uid);
    }

    GetLegitimationForUser(userId: number, uid: string): Observable<SearchLegitimationDto>{
        return this.legitimationService.getLegitimationForUser(userId, uid);
    }

    GetOwnerOfEGovSponsorship(id: number): Observable<GetEGovSponsorshipOwnerLegitimationDto>{
        return this.legitimationService.getOwnerOfEGovSponsorship(id);
    }

    AmIOwner(id: number): Observable<boolean>{
        return this.legitimationService.amIOwner(id);
    }

    AmIOwnerOrCoOwner(id: number): Observable<boolean>{
        return this.legitimationService.amIOwnerOrCoOwner(id);
    }

    GetLegitimation(id: number): Observable<SearchLegitimationDto>{
        return this.legitimationService.getLegitimation(id);
    }

    UpdateLegitimation(id: number, dto: LegitimationDto): Observable<number>{
        return this.legitimationService.updateLegitimation(id, dto);
    }

    CreateLegitimation(dto: LegitimationDto): Observable<number>{
        return this.legitimationService.createLegitimation(dto);
    }

    IsActive(dto: LegitimationDto): boolean {
        return dto?.status === LegitimationStatus.Active;
            //    (dto?.startDate == null || dto?.startDate <= DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) &&
            //    (dto?.endDate == null || dto?.endDate >= DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) ;
    }

    SetStatus(legitimationId: number, status: LegitimationStatus){
        return this.legitimationService.updateLegitimationStatus(legitimationId, new SetLegitimationStatusDto({status: status}));
    }

}
